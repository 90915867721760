<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Buses</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active">Buses</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link
              v-can="'add_buses'"
              :to="{ name: 'Buses-Create' }"
              class="btn btn-primary"
              >Add New</router-link
            >
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <!-- form will be here -->
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped verticle-middle table-responsive-sm"
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Tracking ID</th>
                    <th scope="col">Capacity</th>
                    <th scope="col">Drivers</th>
                    <th scope="col">Supervisors</th>
                    <th scope="col">Students</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody v-if="buses.length">
                  <tr v-for="bus in buses" :key="bus.id">
                    <td>{{ bus.name }}</td>
                    <td>{{ bus.bus_tracking_id }}</td>
                    <td>{{ bus.capacity }}</td>
                    <td>
                      <div v-if="bus.drivers">
                        <ul>
                          <li
                            v-for="driver in bus.drivers"
                            :key="driver.id"
                            style="list-style: unset; margin-left: 10px"
                          >
                            {{ driver.code }}
                            {{ driver.firstname }}
                            {{ driver.middlename }}
                            {{ driver.lastname }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div v-if="bus.supervisors">
                        <ul>
                          <li
                            v-for="supervisor in bus.supervisors"
                            :key="supervisor.id"
                            style="list-style: unset; margin-left: 10px"
                          >
                            {{ supervisor.code }}
                            {{ supervisor.firstname }}
                            {{ supervisor.middlename }}
                            {{ supervisor.lastname }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div v-if="bus.students">
                        {{ bus.students.length }}
                      </div>
                    </td>
                    <td>
                      <span>
                        <router-link
                          v-can="'edit_buses'"
                          :to="{
                            name: 'Buses-Show',
                            params: { id: bus.id },
                          }"
                          title="View"
                          class="mr-4"
                          ><i class="fa fa-eye color-muted"></i>
                        </router-link>
                        <router-link
                          v-can="'edit_buses'"
                          :to="{
                            name: 'Buses-Edit',
                            params: { id: bus.id },
                          }"
                          title="Edit"
                          class="mr-4"
                          ><i class="fa fa-pencil color-muted"></i>
                        </router-link>
                        <a
                          v-can="'delete_buses'"
                          @click.prevent="deleteData(bus.id)"
                          href="javascript:void()"
                          title="Delete"
                          ><i class="fa fa-close color-danger"></i
                        ></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="9">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usebuses from "@/composables/buses";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const { buses, getBuses, deleteBus } = usebuses();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_buses")) {
        router.push({
          name: "401",
        });
      } else {
        getBuses();
      }
    });

    const deleteData = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteBus(id).then(() => {
              swal.fire(
                "Deleted!",
                "The data has been deleted successfully.",
                "success"
              );
              getBuses();
            });
          }
        });
    };

    return {
      buses,
      deleteData,
    };
  },
};
</script>
