import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function usebuses() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const bus = ref({});
    const buses = ref({});
    const childrens = ref({});
    const lists = reactive({});


    const getBuses = async () => {
        try {
            let response = await axios.post('/api/buses/get');
            buses.value = response.data.data.buses;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getBus = async (id) => {
        try {
            let response = await axios.get('/api/buses/' + id);
            bus.value = response.data.data.bus;
            lists.drivers = response.data.data.drivers;
            lists.supervisors = response.data.data.supervisors;
            lists.directors = response.data.data.directors;
            lists.students = response.data.data.students;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }
    const getParentStdBus = async (id) => {
        try {
            let response = await axios.get('/api/parents/buses/' + id);
            bus.value = response.data.data.bus;
            lists.drivers = response.data.data.drivers;
            lists.supervisors = response.data.data.supervisors;
            lists.directors = response.data.data.directors;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getChildrenBuses = async () => {
        try {
            let response = await axios.get('/api/parents/buses/');
            childrens.value = response.data.data.students;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }


    const getLookups = async () => {
        try {
            let response = await axios.get('/api/buses/lookups');
            lists.drivers = response.data.data.drivers;
            lists.supervisors = response.data.data.supervisors;
            lists.directors = response.data.data.directors;
            lists.students = response.data.data.students;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const createBus = async () => {
        errors.value = ''; //
        try {
            return await axios.get('/api/buses/create');
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const storeBus = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/buses/', data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const deleteBus = async (id) => {
        try {
            await axios.delete('/api/buses/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }


    const updateBus = async (data, id) => {
        errors.value = ''; //
        try {
            data.value._method = 'PATCH';
            let response = await axios.post('/api/buses/' + id, data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const getLastLocation = async (bus_id) => {
        try {
            let response = await axios.get('/api/buses/last_location/' + bus_id);
            return response.data.data.location;
        } catch (e) {
            await store.dispatch("handleException", e);
        }
    }


    return {
        bus,
        buses,
        lists,
        msgs,
        errors,
        childrens,
        getLookups,
        getBuses,
        getParentStdBus,
        getChildrenBuses,
        createBus,
        storeBus,
        getBus,
        deleteBus,
        updateBus,
        getLastLocation
    }
}